.section-9 {
    padding: 100px 0 160px;
    background: #F9FAFF;
}

.s9__small {
    display: block;
    text-align: center;
    font-weight: 500;
    font-size: 20px;
    color: #75849E;
}
.s9__headline {
    font-weight: 600;
    font-size: 40px;
    color: #1265FA;
    text-align: center;
    padding-bottom: 5px;
    margin: 15px auto;
}

.s9__controls {
    padding-top: 30px;
    padding-bottom: 20px;
}

.glide__track.s9__track {
    width: 560px;
    margin: 0 auto;
    overflow: visible;
}
.s9__slide {
    flex-basis: 560px;
    overflow: visible;
}

.s9__slide-inner {
    border-radius: 10px;
    height: 360px;
    position: relative;
}
.s9__img {
    border: 4px solid #F9FAFF;
    border-radius: 10px;
    position: absolute;
    width: auto;
    height: auto;
    overflow: hidden;
}
.s9__img > img {
    display: block;
    vertical-align: middle;
    width: 552px;
    height: 352px;
}
.s9__img_left {
    border: 4px solid #1265FA;
}
.s9__img-slider {
    position: absolute;
    z-index: 10;
    cursor: ew-resize;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
}
.s9__border-right {
    position: absolute;
    height: 100%;
    right: 0;
    top: 0;
    border: 4px solid #1265FA;
    /* to hide the actual image behind */
    outline: 10px solid #F9FAFF;
    border-radius: 10px;
}

.s9__caption {
    padding-top: 15px;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #75849E;
    text-align: center;
}

.s9__button-wrap {
    text-align: center;
}
.s9__button {
    width: 360px;
    text-align: center;
    display: inline-block;
    margin: 72px auto 0;
    height: 60px;
    line-height: 60px;
    vertical-align: middle;
    padding: 0;
}

.s9__social-wrap {
    padding-top: 50px;
    height: 0;
    overflow: visible;
    display: flex;
    justify-content: center;
}
.s9__social {
    margin-left: 900px;
    max-width: 260px;
}
.s9__social-text {
    font-weight: 500;
    font-size: 20px;
    line-height: 140%;
    color: #191919;
    padding-bottom: 16px;
}
.s9__social-buttons {
    display: flex;
}
.s9__social-button {
    display: inline-flex;
    width: 60px;
    height: 60px;
    justify-content: center;
    align-items: center;
    background: #1265FA;
    border-radius: 999px;
    margin-right: 20px;
}
.s9__social-button > img {
    width: 31px;
    height: auto;
    filter: brightness(100);
}

@media screen and (max-width: 1250px) {
    .section-9 {
        padding: 100px 0 125px;
    }
    .s9__button {
        margin-top: 34px;
    }
    .s9__social-wrap {
        padding-top: 130px;
        height: initial;
    }
    .s9__social {
        margin-left: 0;
        text-align: center;
    }
    .s9__social-text {
        font-size: 15px;
    }
    .s9__social-buttons {
        justify-content: space-around;
    }
    .s9__social-button {
        margin-right: 0;
    }
    .s9__bottom-wrap {
        display: flex;
        flex-direction: column-reverse;
    }
}

@media screen and (max-width: 870px) {
    .s9__small {
        padding-bottom: 34px;
    }
    .s9__img {
        border: 1px solid white;
    }
    .s9__img_left {
        border: 1px solid #1265FA;
    }
    .s9__border-right {
        border: 1px solid #1265FA;
    }
    .glide__track.s9__track {
        width: 312px;
    }
    .s9__caption {
        font-size: 15px;
        padding-top: 7px;
    }
    .s9__img > img {
        width: 312px;
        height: 202px;
    }
    .s9__slide-inner {
        height: 202px;
    }
    .s9__slide {
        flex-basis: 312px;
    }
    .s9__button-wrap {
        padding: 0 40px;
    }
}
