.section-1 {
    background: #1265FA;
    color: white;
    padding: 80px 0;
}

.section-1__headline {
    font-weight: 600;
    font-size: 40px;
    max-width: 400px;
    padding-bottom: 45px;
    margin-left: 164px;
}

.section-1__inner {
    max-width: 1440px;
    margin: 0 auto;
}

.glide__track.section-1__track {
    margin-left: 164px;
    width: 660px;
    overflow: visible;
}
.section-1__slide {
    flex-basis: 660px;
    width: 660px;
}
.slide1__title {
    font-weight: 600;
    font-size: 40px;
    color: #A2C1F7;
    margin-bottom: 16px;
}
.slide1__image {
    border-radius: 40px;
    width: 660px;
    height: 440px;
}

.section-1__controls {
    margin-left: 854px;
    padding-top: 45px;
}
.slides__control {
    background: white;
    border: none;
    border-radius: 999px;
    height: 60px;
    width: 60px;
    cursor: pointer;
    padding-block: 0;
    padding: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}
.slides__control_long {
    margin-left: 40px;
    width: 160px;
}

@media screen and (max-width: 1200px) {
    .section-1__controls {
        margin-left: 564px;
    }
}

@media screen and (max-width: 1020px) {
    .section__1 {
        padding: 55px 0 100px;
    }
    .glide__track.section-1__track {
        margin-left: 32px;
    }
    .section-1__headline {
        margin-left: 32px;
    }
}

@media screen and (max-width: 870px) {
    .glide__track.section-1__track {
        width: 320px;
    }
    .section-1__slide {
        flex-basis: 320px;
    }
    .slide1__image {
        width: 320px;
        height: 236px;
    }
    .section-1__controls {
        text-align: right;
        margin-left: 0;
        margin-right: 40px;
        padding-top: 26px;
    }
    .slides__control {
        width: 34px;
        height: 34px;
    }
    .slides__control_long {
        width: 90px;
        margin-left: 23px;
    }
    .slides__control > img {
        height: 18px;
        width: auto;
    }
}
