.section-4 {
    padding: 36px 10px 140px;
    max-width: 1180px;
    margin: 0 auto;
}

.s4__top {
    display: flex;
    align-items: flex-start;
    padding-bottom: 60px;
    border-bottom: 2px solid #BDD5FF;
    margin-bottom: 22px;
}
.s4__image {
    margin-right: 40px;
}
.s4__headline {
    margin-top: 64px;
    margin-bottom: 21px;
    font-weight: 600;
    font-size: 40px;
    line-height: 130%;
    color: #1265FA;
}
.s4__text {
    font-weight: 500;
    font-size: 20px;
    line-height: 140%;
    color: #75849E;
    max-width: 400px;
}

.s4__bottom {
    list-style: none;
    display: flex;
    padding: 0;
    justify-content: space-between;
}
.s4__list-item {
    font-weight: 500;
    font-size: 20px;
    line-height: 140%;
    color: #75849E;
    display: flex;
}
.s4__list-emoji {
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    font-size: 35px;
    margin-right: 20px;
    background: #EAEDF8;
    border-radius: 99px;
    flex-shrink: 0;
}

.s4__headline-mobile {
    display: none;
}

@media screen and (max-width: 1050px) {
    .s4__image {
        width: 75%;
        height: auto;
    }
}

@media screen and (max-width: 970px) {
    .section-4 {
        padding: 70px 40px 100px;
    }
    .s4__top {
        flex-direction: column;
        align-items: center;
    }
    .s4__headline-mobile {
        display: block;
        font-weight: 600;
        font-size: 25px;
        line-height: 130%;
        text-align: center;
        color: #1265FA;
    }
    .s4__headline {
        display: none;
    }
    .s4__image {
        margin-right: 0;
    }
    .s4__text {
        margin-top: 14px;
        max-width: 600px;
    }
    .s4__bottom {
        flex-direction: column;
        flex-wrap: wrap;
        align-items: flex-start;
        max-width: 400px;
        margin: 0 auto;
    }
    .s4__list-item {
        padding-bottom: 13px;
    }
}

@media screen and (max-width: 500px) {
    .s4__image {
        width: 100%;
    }
    .s4__text {
        font-size: 15px;
    }
    .s4__bottom {
        max-width: 100%;
    }
    .s4__list-item {
        font-size: 15px;
    }
    .s4__list-emoji {
        margin-right: 16px;
        width: 45px;
        height: 45px;
        line-height: 45px;
        font-size: 25px;
    }
}
