.glide {
  width: 100%;
  box-sizing: border-box;
  position: relative;
}

.glide * {
  box-sizing: inherit;
}

.glide__track {
  overflow: hidden;
}

.glide__slides {
  width: 100%;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  touch-action: pan-Y;
  white-space: nowrap;
  will-change: transform;
  flex-wrap: nowrap;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  position: relative;
  overflow: hidden;
}

.glide__slides--dragging {
  user-select: none;
}

.glide__slide {
  width: 100%;
  height: 100%;
  white-space: normal;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  flex-shrink: 0;
}

.glide__slide a {
  user-select: none;
  -webkit-user-drag: none;
}

.glide__arrows, .glide__bullets {
  -webkit-touch-callout: none;
  user-select: none;
}

.glide--rtl {
  direction: rtl;
}

.header {
  max-width: 1160px;
  align-items: flex-start;
  margin: 0 auto;
  padding: 7px 10px 0;
  display: flex;
  position: relative;
}

.header__logo {
  padding-right: 16px;
}

.header__description {
  color: #5b6270;
  padding-top: 5px;
  line-height: 1.4;
}

.header__description b {
  font-weight: 700;
}

.header__location-icon {
  height: 14px;
}

.header__nav {
  flex-grow: 1;
  justify-content: space-between;
  margin-left: 56px;
  margin-right: 56px;
  padding-top: 8px;
  display: flex;
}

.nav-item {
  color: #5b6270;
  text-decoration: none;
}

.nav-item:hover {
  text-decoration: underline;
}

.header__call {
  flex-direction: column;
  align-items: flex-end;
  padding-top: 5px;
  padding-right: 14px;
  display: flex;
}

.header__number {
  color: #5b6270;
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;
}

.header__call-me-back {
  color: #5b6270;
  font-size: 14px;
}

.header__buttons {
  height: 0;
  flex-direction: column;
  align-items: center;
  display: flex;
  overflow-y: visible;
}

.circle-button {
  width: 28px;
  height: 28px;
  background: #00ed89;
  border-radius: 50%;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  margin-bottom: 6px;
  display: flex;
}

.circle-button_big {
  width: 44px;
  height: 44px;
  margin-bottom: 13px;
}

.circle-button_gold {
  background: #f59201;
}

.header__menu-toggle {
  display: none;
}

@media screen and (max-width: 1100px) {
  .header__nav {
    margin-left: 28px;
    margin-right: 28px;
  }
}

@media screen and (max-width: 1020px) {
  .header__nav, .header__call, .header__buttons {
    display: none;
  }

  .header {
    text-align: center;
    flex-direction: column;
    align-items: center;
  }

  .header__logo {
    width: 150px;
    height: auto;
    padding-right: 0;
  }

  .header__description {
    margin-top: -15px;
  }

  .header__menu-toggle {
    display: block;
    position: absolute;
    top: 26px;
    right: 40px;
  }
}

.section__top {
  width: 100%;
  min-height: 100vh;
  background: url("top.328906c2.jpg") top / cover no-repeat;
  padding-bottom: 70px;
  position: relative;
}

.top-card {
  max-width: 497px;
  z-index: 10;
  background-color: #fff;
  border-radius: 21px;
  flex-direction: column;
  align-items: center;
  margin: 64px auto 0;
  padding: 32px 0;
  display: flex;
  position: relative;
}

.top-card__headline {
  color: #0085ff;
  text-transform: uppercase;
  text-shadow: 0 1px #0258a8;
  text-align: center;
  padding-bottom: 30px;
  font-size: 38px;
  font-weight: 700;
}

.emoji-list {
  padding-inline-start: 0;
  padding-bottom: 40px;
  list-style: none;
  display: inline-block;
}

.emoji-list__item {
  color: #1265fa;
  max-width: 400px;
  align-items: center;
  padding-bottom: 10px;
  font-size: 20px;
  display: flex;
}

.emoji-list__emoji {
  vertical-align: middle;
  padding-right: 16px;
  font-size: 28px;
}

.top-card__promo {
  text-align: center;
  color: #1265fa;
  max-width: 400px;
  padding-bottom: 20px;
  font-size: 20px;
  font-weight: 500;
}

.top-card__promo b {
  font-weight: 700;
}

.giant-input {
  color: #1265fa;
  width: 335px;
  max-width: 100%;
  background: #eaedf8;
  border: none;
  border-radius: 999px;
  margin-bottom: 20px;
  padding: 20px 40px;
  font-size: 20px;
  font-weight: 500;
  line-height: 140%;
}

.giant-button {
  color: #fff;
  cursor: pointer;
  width: 335px;
  max-width: 100%;
  background: linear-gradient(#f8a100 0%, #ec6900 100%);
  border: none;
  border-radius: 999px;
  padding: 20px 40px;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  text-decoration: none;
}

.top-card__small {
  max-width: 335px;
  color: #f8a100;
  text-align: center;
  padding: 20px 0 2px;
  font-size: 10px;
  font-weight: 400;
}

@media screen and (max-width: 1020px) {
  .section__top {
    background-color: #ebebeb;
    background-image: url("top-mobile.14820320.png");
    background-position-y: 34px;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .section__top:before {
    content: "";
    width: 100%;
    height: 100%;
    background: linear-gradient(180.2deg, #ebebeb00 28.56%, #ebebeb 39.89%);
    position: absolute;
    top: 0;
    left: 0;
  }

  .top-card__wrap {
    padding: 0 16px;
  }

  .top-card {
    margin: 160px auto 90px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .top-card__headline {
    padding-bottom: 10px;
    font-size: 27px;
  }

  .emoji-list__item {
    font-size: 16px;
  }

  .emoji-list__item:first-letter {
    font-size: 24px;
  }

  .emoji-list {
    width: 100%;
    padding-bottom: 17px;
  }

  .emoji-list__item {
    padding-bottom: 4px;
  }
}

.section-1 {
  color: #fff;
  background: #1265fa;
  padding: 80px 0;
}

.section-1__headline {
  max-width: 400px;
  margin-left: 164px;
  padding-bottom: 45px;
  font-size: 40px;
  font-weight: 600;
}

.section-1__inner {
  max-width: 1440px;
  margin: 0 auto;
}

.glide__track.section-1__track {
  width: 660px;
  margin-left: 164px;
  overflow: visible;
}

.section-1__slide {
  width: 660px;
  flex-basis: 660px;
}

.slide1__title {
  color: #a2c1f7;
  margin-bottom: 16px;
  font-size: 40px;
  font-weight: 600;
}

.slide1__image {
  width: 660px;
  height: 440px;
  border-radius: 40px;
}

.section-1__controls {
  margin-left: 854px;
  padding-top: 45px;
}

.slides__control {
  height: 60px;
  width: 60px;
  cursor: pointer;
  background: #fff;
  border: none;
  border-radius: 999px;
  justify-content: center;
  align-items: center;
  padding: 0;
  display: inline-flex;
}

.slides__control_long {
  width: 160px;
  margin-left: 40px;
}

@media screen and (max-width: 1200px) {
  .section-1__controls {
    margin-left: 564px;
  }
}

@media screen and (max-width: 1020px) {
  .section__1 {
    padding: 55px 0 100px;
  }

  .glide__track.section-1__track, .section-1__headline {
    margin-left: 32px;
  }
}

@media screen and (max-width: 870px) {
  .glide__track.section-1__track {
    width: 320px;
  }

  .section-1__slide {
    flex-basis: 320px;
  }

  .slide1__image {
    width: 320px;
    height: 236px;
  }

  .section-1__controls {
    text-align: right;
    margin-left: 0;
    margin-right: 40px;
    padding-top: 26px;
  }

  .slides__control {
    width: 34px;
    height: 34px;
  }

  .slides__control_long {
    width: 90px;
    margin-left: 23px;
  }

  .slides__control > img {
    height: 18px;
    width: auto;
  }
}

.section-2 {
  padding-top: 120px;
  padding-bottom: 170px;
}

.section-2__inner {
  max-width: 1160px;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0 20px;
  list-style: none;
  display: flex;
}

.section-2__card {
  max-width: 330px;
  flex-basis: 330px;
}

.s2-image__wrap {
  height: 460px;
  align-items: center;
  display: flex;
}

.s2-card__title {
  color: #1265fa;
  border-bottom: 2px solid #bdd5ff;
  margin-bottom: 30px;
  padding-top: 18px;
  padding-bottom: 20px;
  font-size: 28px;
  font-weight: 600;
}

.s2-card__subtitle {
  color: #75849e;
  font-size: 20px;
  font-weight: 500;
}

@media screen and (max-width: 1075px) {
  .section-2__inner {
    max-width: 800px;
  }

  .section-2__card.mobile-hide {
    display: none;
  }
}

@media screen and (max-width: 725px) {
  .section-2__card.mobile-hide {
    display: block;
  }

  .section-2__inner {
    max-width: 370px;
    flex-direction: column;
  }

  .section-2__card {
    margin-bottom: 85px;
  }
}

@media screen and (max-width: 400px) {
  .s2-image__wrap, .s2-image {
    width: 100%;
    height: auto;
  }
}

.section-3 {
  background: #eaedf8;
  padding: 140px 0;
}

.section-3__inner {
  max-width: 1160px;
  color: #fff;
  background: #1265fa;
  border-radius: 90px;
  margin: 0 auto;
  display: flex;
}

.s3__img {
  max-width: 100%;
}

.s3__img_top {
  border-radius: 90px 90px 0 0;
}

.s3__img_bottom {
  border-radius: 0 0 90px 90px;
  margin-top: -250px;
  margin-bottom: -4px;
}

.s3-right {
  flex-direction: column;
  justify-content: space-between;
  padding: 70px 40px 70px 80px;
  display: flex;
}

.s3__h2 {
  padding-bottom: 28px;
  font-size: 40px;
  font-weight: 600;
}

.s3__list {
  padding: 0;
  list-style: none;
}

.s3__list-item {
  padding-bottom: 25px;
  font-size: 20px;
  font-weight: 500;
  line-height: 140%;
  display: flex;
}

.s3__list-emoji {
  width: 60px;
  height: 60px;
  text-align: center;
  background: #fff;
  border-radius: 99px;
  flex-shrink: 0;
  flex-basis: 60px;
  margin-right: 25px;
  font-size: 30px;
  line-height: 60px;
}

.s3__img_right {
  display: none;
}

.s3__h3 {
  padding-bottom: 12px;
  font-size: 36px;
  font-weight: 600;
}

.s3__text {
  padding-bottom: 12px;
  font-size: 20px;
  font-weight: 500;
  line-height: 140%;
}

.s3__text b {
  font-weight: 700;
}

.s3__form {
  padding-top: 44px;
}

.giant-input.giant-input_s3 {
  width: 400px;
  max-width: 100%;
  color: #fff;
  background: none;
  border: 2px solid #d3ddf8;
  padding: 20px 44px;
}

.giant-button.giant-button_s3 {
  width: 400px;
  max-width: 100%;
  padding: 16px 0;
  font-size: 20px;
}

.giant-button_s3:first-letter {
  vertical-align: middle;
  font-size: 30px;
}

.s3__small {
  margin-top: 20px;
  margin-left: 22px;
  margin-right: 60px;
  font-size: 10px;
  line-height: 120%;
  display: block;
}

@media screen and (max-width: 1100px) {
  .s3-left {
    flex-direction: column;
    flex-shrink: 0;
    flex-basis: 40%;
    justify-content: space-between;
    display: flex;
  }

  .s3__img_bottom {
    margin-top: 0;
  }
}

@media screen and (max-width: 900px) {
  .s3-left {
    flex-basis: 40%;
  }

  .s3-right {
    padding-left: 30px;
    padding-right: 20px;
  }
}

@media screen and (max-width: 750px) {
  .section-3 {
    background: none;
    padding: 0;
  }

  .section-3__inner {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    flex-direction: column;
  }

  .s3__img_bottom {
    display: none;
  }

  .s3-right {
    padding: 0;
  }

  .s3__h2 {
    padding: 0 24px 22px;
    font-size: 25px;
    font-weight: 600;
  }

  .s3__list-item {
    padding: 0 24px 15px;
    font-size: 15px;
    font-weight: 500;
    line-height: 140%;
  }

  .s3__list-item_pad-right {
    max-width: 80%;
  }

  .s3__img_right {
    width: 100%;
    margin-top: -135px;
    display: block;
  }

  .s3-bottom {
    padding: 0 0 100px;
  }

  .s3__h3 {
    text-align: center;
    padding: 70px 24px 15px;
    font-size: 25px;
    font-weight: 600;
  }

  .s3__text {
    text-align: center;
    padding: 0 24px 2px;
    font-size: 17px;
  }

  .s3__form {
    max-width: 326px;
    margin: 0 auto;
  }

  .s3__small {
    text-align: center;
    margin: 10px 64px 0;
  }
}

.section-4 {
  max-width: 1180px;
  margin: 0 auto;
  padding: 36px 10px 140px;
}

.s4__top {
  border-bottom: 2px solid #bdd5ff;
  align-items: flex-start;
  margin-bottom: 22px;
  padding-bottom: 60px;
  display: flex;
}

.s4__image {
  margin-right: 40px;
}

.s4__headline {
  color: #1265fa;
  margin-top: 64px;
  margin-bottom: 21px;
  font-size: 40px;
  font-weight: 600;
  line-height: 130%;
}

.s4__text {
  color: #75849e;
  max-width: 400px;
  font-size: 20px;
  font-weight: 500;
  line-height: 140%;
}

.s4__bottom {
  justify-content: space-between;
  padding: 0;
  list-style: none;
  display: flex;
}

.s4__list-item {
  color: #75849e;
  font-size: 20px;
  font-weight: 500;
  line-height: 140%;
  display: flex;
}

.s4__list-emoji {
  width: 60px;
  height: 60px;
  text-align: center;
  background: #eaedf8;
  border-radius: 99px;
  flex-shrink: 0;
  margin-right: 20px;
  font-size: 35px;
  line-height: 60px;
}

.s4__headline-mobile {
  display: none;
}

@media screen and (max-width: 1050px) {
  .s4__image {
    width: 75%;
    height: auto;
  }
}

@media screen and (max-width: 970px) {
  .section-4 {
    padding: 70px 40px 100px;
  }

  .s4__top {
    flex-direction: column;
    align-items: center;
  }

  .s4__headline-mobile {
    text-align: center;
    color: #1265fa;
    font-size: 25px;
    font-weight: 600;
    line-height: 130%;
    display: block;
  }

  .s4__headline {
    display: none;
  }

  .s4__image {
    margin-right: 0;
  }

  .s4__text {
    max-width: 600px;
    margin-top: 14px;
  }

  .s4__bottom {
    max-width: 400px;
    flex-flow: column wrap;
    align-items: flex-start;
    margin: 0 auto;
  }

  .s4__list-item {
    padding-bottom: 13px;
  }
}

@media screen and (max-width: 500px) {
  .s4__image {
    width: 100%;
  }

  .s4__text {
    font-size: 15px;
  }

  .s4__bottom {
    max-width: 100%;
  }

  .s4__list-item {
    font-size: 15px;
  }

  .s4__list-emoji {
    width: 45px;
    height: 45px;
    margin-right: 16px;
    font-size: 25px;
    line-height: 45px;
  }
}

.section-5 {
  padding: 130px 0 188px;
}

.s5__small {
  text-align: center;
  color: #75849e;
  font-size: 20px;
  font-weight: 500;
  display: block;
}

.s5__headline {
  color: #1265fa;
  max-width: 740px;
  text-align: center;
  margin: 10px auto;
  font-size: 40px;
  font-weight: 600;
}

.s5__controls {
  justify-content: center;
  margin-bottom: 20px;
  display: flex;
}

.s5__control-left {
  margin-left: 900px;
}

.slides__control_secondary {
  background: #eaedf8;
}

.glide__track.s5__track {
  width: 560px;
  margin: 0 auto;
  overflow: visible;
}

.s5__slide {
  height: 360px;
  background: #eaedf8;
  border-radius: 40px;
  flex-basis: 260px;
}

.s5__controls-mobile {
  display: none;
}

.s5__bottom-wrapper {
  width: 560px;
  margin: 56px auto 0;
  overflow: visible;
}

.s5__bottom {
  color: #75849e;
  width: 100%;
  margin-left: 300px;
  font-size: 20px;
  font-weight: 500;
}

.s5__socials {
  vertical-align: middle;
  padding-top: 7px;
}

.s5__social {
  color: #75849e;
  height: 40px;
  background: #eaedf8;
  border-radius: 99px;
  padding: 0 30px 0 20px;
  font-size: 20px;
  line-height: 40px;
  text-decoration: none;
  display: inline-block;
}

.s5__social-image {
  vertical-align: middle;
  margin-right: 12px;
  line-height: 40px;
}

@media screen and (max-width: 1200px) {
  .s5__control-left {
    margin-left: 300px;
  }
}

@media screen and (max-width: 870px) {
  .s5__controls {
    display: none;
  }

  .s5__controls-mobile {
    text-align: right;
    margin-top: 6px;
    margin-right: 39px;
    display: block;
  }

  .s5__small {
    font-size: 15px;
  }

  .s5__headline {
    max-width: 310px;
    margin-bottom: 40px;
    font-size: 25px;
  }

  .section-5 {
    padding: 72px 0 62px;
  }
}

@media screen and (max-width: 800px) {
  .s5__bottom {
    margin: 33px 0 0;
    font-size: 15px;
  }

  .s5__social {
    height: 33px;
    font-size: 17px;
    line-height: 33px;
  }

  .s5__social-image {
    width: 18px;
    height: auto;
    margin-right: 10px;
  }

  .s5__bottom-wrapper {
    width: initial;
    margin: 0 auto;
  }

  .s5__bottom {
    text-align: center;
  }
}

@media screen and (max-width: 750px) {
  .glide__track.s5__track {
    width: 230px;
    margin: 0 0 0 40px;
  }

  .s5__slide {
    flex-basis: 230px;
  }
}

.section-6 {
  max-width: 1200px;
  margin: 0 auto;
  padding: 145px 20px;
}

.s6__headline {
  color: #1265fa;
  max-width: 700px;
  padding-bottom: 25px;
  font-size: 40px;
  font-weight: 600;
  line-height: 130%;
}

.s6__headline u {
  font-weight: 600;
}

.s6__table {
  width: 100%;
  border-collapse: collapse;
}

.s6__thead > tr > td {
  border-bottom: 2px solid #1265fa;
  padding: 20px 0;
}

.s6__thead.s6__mobile-hide > tr > td {
  padding-top: 74px;
}

.s6__accent {
  color: #1265fa;
  font-size: 28px;
  font-weight: 600;
  line-height: 130%;
}

.s6__secondary {
  color: #75849e;
  font-size: 20px;
  font-weight: 500;
  line-height: 140%;
}

.s6__secondary > u {
  font-weight: 500;
}

.s6__left {
  min-width: 200px;
}

.s6__row > td:not(.s6__left) {
  vertical-align: top;
  border-bottom: 2px solid #bdd5ff;
  padding-top: 10px;
  padding-bottom: 40px;
  padding-right: 10px;
}

.s6__row:last-child > td {
  border-bottom: none;
}

.s6__row > td.s6__image {
  width: 60px;
  padding-top: 15px;
  padding-right: 40px;
}

.s6__type {
  color: #191919;
  font-size: 20px;
  font-weight: 600;
  line-height: 140%;
}

.s6__price-mobile, .s6__mobile-num {
  display: none;
}

@media screen and (max-width: 1000px) {
  .s6__left {
    min-width: 100px;
  }
}

@media screen and (max-width: 840px) {
  .section-6 {
    padding: 87px 40px 50px;
  }

  .s6__headline {
    text-align: center;
    width: 100%;
    padding-bottom: 30px;
    font-size: 25px;
  }

  .s6__row > td.s6__image {
    border-bottom: none;
  }

  .s6__secondary {
    display: none;
  }

  .s6__price-mobile {
    justify-content: space-between;
    padding-top: 6px;
    font-size: 15px;
    display: flex;
  }

  .s6__price-right {
    text-align: right;
  }

  .s6__left {
    display: none;
  }

  .s6__thead > tr > td {
    border-bottom: none;
    padding: 0;
  }

  .s6__thead > tr > td.s6__subtitle {
    border-bottom: 2px solid #1265fa;
    padding-bottom: 13px;
  }

  .s6__accent {
    font-size: 25px;
  }

  .s6__mobile-num {
    font-weight: 600;
    display: block;
  }

  .s6__row > td.s6__image {
    padding-right: 20px;
  }

  .s6__type {
    font-size: 15px;
  }

  .s6__mobile-hide {
    display: none;
  }
}

.section-7 {
  min-height: 904px;
  background: url("s7-p2.d8fc5213.png") top / 75% no-repeat;
  position: relative;
}

.s7__hand {
  width: 75%;
  height: 100%;
  background: url("s7-p1.c642f265.png") top / 100% no-repeat;
  border-top-right-radius: 29px;
  position: absolute;
  top: 0;
  right: 50%;
  transform: translateX(50%);
}

.s7__card {
  width: 644px;
  background: #fff;
  border-radius: 45px;
  padding: 30px 35px 80px 50px;
  position: absolute;
  top: 20%;
  right: 52%;
}

.s7__headline {
  color: #0085ff;
  max-width: 470px;
  padding-bottom: 5px;
  font-size: 40px;
  font-weight: 600;
  line-height: 130%;
}

.s7__text {
  color: #75849e;
  max-width: 360px;
  font-size: 20px;
  font-weight: 500;
  line-height: 140%;
}

.s7__bottom {
  flex-direction: column;
  align-items: center;
  display: flex;
  position: absolute;
  bottom: 130px;
  left: 50%;
  transform: translateX(-50%);
}

.s7__bottom-text {
  text-align: center;
  color: #75849e;
  margin: 20px 0;
  font-size: 20px;
  font-weight: 500;
  line-height: 140%;
}

.s7__socials {
  display: flex;
}

.s7__social-col {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.s7__social {
  width: 90px;
  height: 90px;
  background: #1265fa;
  border-radius: 999px;
  justify-content: center;
  align-items: center;
  margin: 20px 20px 0;
  display: flex;
}

.s7__social > img {
  width: 50px;
  height: auto;
  filter: brightness(10);
}

@media screen and (max-height: 840px) {
  .section-7 {
    min-height: 780px;
  }

  .s7__bottom {
    bottom: 15px;
  }
}

@media screen and (max-width: 1400px) {
  .s7__card {
    width: 500px;
    right: 55%;
  }

  .section-7 {
    background-size: 100%;
  }

  .s7__hand {
    width: 100%;
    background-size: 100%;
  }
}

@media screen and (max-width: 1140px) {
  .s7__hand {
    background-size: 75%;
  }

  .section-7 {
    min-height: 600px;
    background-size: 75%;
  }

  .s7__card {
    top: -10%;
    right: 50%;
  }
}

@media screen and (max-width: 950px) {
  .s7__card-wrapper {
    max-width: 309px;
    margin: 0 auto;
    padding: 217px 0 0;
  }

  .s7__card {
    max-width: 309px;
    border-radius: 10px;
    padding: 380px 23px 40px;
    position: static;
  }

  .s7__headline {
    padding-bottom: 15px;
    font-size: 25px;
  }

  .s7__text {
    font-size: 15px;
  }

  .section-7 {
    background-position: 0 0;
    background-size: cover;
  }

  .s7__hand {
    background-position: right -30px top 0;
    background-size: 172%;
  }

  .s7__bottom {
    flex-direction: column-reverse;
    margin: 0 auto;
    position: static;
    transform: none;
  }

  .s7__bottom-text {
    font-size: 15px;
  }

  .s7__socials {
    transform: scale(85%);
  }
}

@media screen and (max-width: 700px) {
  .s7__card {
    padding: 220px 23px 40px;
  }
}

@media screen and (max-width: 480px) {
  .s7__card {
    padding: 75px 23px 200px;
  }

  .s7__bottom {
    margin-top: -190px;
    padding-bottom: 120px;
  }
}

.section-8 {
  background: #f9faff;
  padding: 140px 20px 155px;
}

.s8__inner {
  max-width: 1160px;
  margin: 0 auto;
  position: relative;
}

.s8__video {
  height: 600px;
  display: block;
  position: relative;
}

.s8__image-back, .s8__image-front {
  position: absolute;
  bottom: 0;
  left: 0;
}

.s8__card-wrap {
  max-width: 500px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.s8__card {
  background: #fff;
  border-radius: 40px;
  padding: 28px 40px 40px;
}

.s8__subtitle {
  color: #1265fa;
  margin-bottom: 1px;
  font-size: 20px;
  font-weight: 500;
  line-height: 140%;
}

.s8__title {
  color: #1265fa;
  margin-bottom: 24px;
  font-size: 40px;
  font-weight: 600;
  line-height: 130%;
}

.emoji-list_s8 {
  padding-bottom: 50px;
}

.emoji-list__item_s8 {
  align-items: flex-start;
  padding-bottom: 7px;
}

.emoji-list__emoji_s8 {
  padding-right: 26px;
}

.s8__button {
  width: 100%;
}

.s8__note {
  text-align: center;
  color: #75849e;
  margin-top: 34px;
  font-size: 20px;
  font-weight: 500;
  line-height: 140%;
}

.s8__note b {
  font-weight: 700;
}

.s8__button-mobile-wrap, .s8__subtitle_top {
  display: none;
}

@media screen and (max-width: 1000px) {
  .s8__title {
    font-size: 25px;
  }

  .emoji-list__item_s8 {
    padding-bottom: 5px;
    font-size: 15px;
  }

  .emoji-list__emoji_s8 {
    font-size: 24px;
  }
}

@media screen and (max-width: 900px) {
  .s8__card-wrap {
    max-width: 420px;
  }
}

@media screen and (max-width: 800px) {
  .section-8 {
    padding-top: 70px;
    padding-bottom: 102px;
  }

  .s8__card-wrap {
    max-width: 560px;
    margin: 10px auto;
    padding: 0 20px;
    position: static;
  }

  .s8__card {
    padding: 19px 23px 34px;
  }

  .s8__subtitle {
    display: none;
  }

  .s8__subtitle_top {
    margin-bottom: -30px;
    padding-left: 20px;
    font-size: 15px;
    display: block;
  }

  .s8__video {
    width: 100%;
    max-width: 560px;
    height: 600px;
    margin: 0 auto;
    padding: 0 20px;
    display: block;
    position: relative;
    overflow-x: visible;
  }

  .s8__video-inner {
    width: 100%;
    height: 100%;
    position: relative;
    top: 0;
    left: 0;
  }

  .s8__image-front {
    width: 100%;
  }

  .s8__image-back {
    width: 139%;
  }

  .emoji-list_s8 {
    padding-bottom: 0;
  }

  .s8__button {
    display: none;
  }

  .s8__button-mobile-wrap {
    text-align: center;
    margin-top: 22px;
    padding: 0 20px;
    display: block;
  }

  .s8__button-mobile {
    width: 100%;
    max-width: 520px;
  }

  .s8__note {
    margin-top: 22px;
    font-size: 15px;
  }
}

@media screen and (max-width: 520px) {
  .s8__video {
    height: 410px;
  }
}

@media screen and (max-width: 420px) {
  .s8__video {
    height: 330px;
  }
}

.section-9 {
  background: #f9faff;
  padding: 100px 0 160px;
}

.s9__small {
  text-align: center;
  color: #75849e;
  font-size: 20px;
  font-weight: 500;
  display: block;
}

.s9__headline {
  color: #1265fa;
  text-align: center;
  margin: 15px auto;
  padding-bottom: 5px;
  font-size: 40px;
  font-weight: 600;
}

.s9__controls {
  padding-top: 30px;
  padding-bottom: 20px;
}

.glide__track.s9__track {
  width: 560px;
  margin: 0 auto;
  overflow: visible;
}

.s9__slide {
  flex-basis: 560px;
  overflow: visible;
}

.s9__slide-inner {
  height: 360px;
  border-radius: 10px;
  position: relative;
}

.s9__img {
  width: auto;
  height: auto;
  border: 4px solid #f9faff;
  border-radius: 10px;
  position: absolute;
  overflow: hidden;
}

.s9__img > img {
  vertical-align: middle;
  width: 552px;
  height: 352px;
  display: block;
}

.s9__img_left {
  border: 4px solid #1265fa;
}

.s9__img-slider {
  z-index: 10;
  cursor: ew-resize;
  position: absolute;
  top: 50%;
  transform: translateY(-50%)translateX(-50%);
}

.s9__border-right {
  height: 100%;
  border: 4px solid #1265fa;
  border-radius: 10px;
  outline: 10px solid #f9faff;
  position: absolute;
  top: 0;
  right: 0;
}

.s9__caption {
  color: #75849e;
  text-align: center;
  padding-top: 15px;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
}

.s9__button-wrap {
  text-align: center;
}

.s9__button {
  width: 360px;
  text-align: center;
  height: 60px;
  vertical-align: middle;
  margin: 72px auto 0;
  padding: 0;
  line-height: 60px;
  display: inline-block;
}

.s9__social-wrap {
  height: 0;
  justify-content: center;
  padding-top: 50px;
  display: flex;
  overflow: visible;
}

.s9__social {
  max-width: 260px;
  margin-left: 900px;
}

.s9__social-text {
  color: #191919;
  padding-bottom: 16px;
  font-size: 20px;
  font-weight: 500;
  line-height: 140%;
}

.s9__social-buttons {
  display: flex;
}

.s9__social-button {
  width: 60px;
  height: 60px;
  background: #1265fa;
  border-radius: 999px;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  display: inline-flex;
}

.s9__social-button > img {
  width: 31px;
  height: auto;
  filter: brightness(100);
}

@media screen and (max-width: 1250px) {
  .section-9 {
    padding: 100px 0 125px;
  }

  .s9__button {
    margin-top: 34px;
  }

  .s9__social-wrap {
    height: initial;
    padding-top: 130px;
  }

  .s9__social {
    text-align: center;
    margin-left: 0;
  }

  .s9__social-text {
    font-size: 15px;
  }

  .s9__social-buttons {
    justify-content: space-around;
  }

  .s9__social-button {
    margin-right: 0;
  }

  .s9__bottom-wrap {
    flex-direction: column-reverse;
    display: flex;
  }
}

@media screen and (max-width: 870px) {
  .s9__small {
    padding-bottom: 34px;
  }

  .s9__img {
    border: 1px solid #fff;
  }

  .s9__img_left, .s9__border-right {
    border: 1px solid #1265fa;
  }

  .glide__track.s9__track {
    width: 312px;
  }

  .s9__caption {
    padding-top: 7px;
    font-size: 15px;
  }

  .s9__img > img {
    width: 312px;
    height: 202px;
  }

  .s9__slide-inner {
    height: 202px;
  }

  .s9__slide {
    flex-basis: 312px;
  }

  .s9__button-wrap {
    padding: 0 40px;
  }
}

.section-10 {
  background: #eae9e7 url("s10-p1.10fa50c9.png") right 52px top 29px / contain no-repeat;
}

.s10__inner {
  max-width: 1200px;
  margin: 0 auto;
  padding: 100px 20px;
}

.s10__small {
  color: #75849e;
  font-size: 20px;
  font-weight: 500;
  line-height: 140%;
}

.s10__headline {
  color: #1265fa;
  max-width: 450px;
  margin: 10px 0 43px;
  font-size: 40px;
  font-weight: 600;
  line-height: 130%;
}

.s10__video {
  width: 588px;
  max-width: 100%;
  height: auto;
  background: #eae9e7;
  border: 4px solid #5693ff;
  border-radius: 40px;
  margin-bottom: 42px;
  display: block;
  position: relative;
}

.s10__video-preview {
  max-width: 100%;
  border-radius: 35px;
}

.s10__play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

.s10__cards {
  justify-content: flex-start;
  display: flex;
}

.s10__card {
  width: 250px;
  height: 204px;
  background: #fff;
  border-radius: 20px;
  margin-right: 11px;
  padding: 20px;
}

.s10__card-emoji {
  width: 60px;
  height: 60px;
  vertical-align: middle;
  text-align: center;
  background: #eaedf8;
  border-radius: 99px;
  margin-bottom: 20px;
  font-size: 36px;
  line-height: 60px;
}

.s10__card-text {
  color: #75849e;
  font-size: 20px;
  font-weight: 500;
  line-height: 140%;
}

.s10__button {
  text-align: center;
  margin-left: 28px;
  padding: 0;
  line-height: 204px;
}

.s10__mobile-image {
  display: none;
}

@media screen and (max-width: 1150px) {
  .s10__cards {
    flex-wrap: wrap;
  }

  .s10__button {
    height: 70px;
    max-width: 313px;
    width: 100%;
    margin-top: 20px;
    margin-left: 0;
    line-height: 70px;
  }

  .section-10 {
    background-position: right top 20px;
    background-size: 60%;
  }
}

@media screen and (max-width: 850px) {
  .section-10 {
    background: #eae9e7;
  }

  .s10__cards {
    flex-direction: column;
  }

  .s10__card {
    height: 93px;
    max-width: 400px;
    width: 100%;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5px;
    margin-right: 0;
    padding: 16px;
    display: flex;
  }

  .s10__card-emoji {
    flex-shrink: 0;
    margin-bottom: 0;
    margin-right: 23px;
  }

  .s10__card-text {
    font-size: 15px;
  }

  .s10__button {
    margin-top: 37px;
  }

  .s10__small {
    font-size: 15px;
  }

  .s10__headline {
    font-size: 25px;
  }

  .s10__mobile-image {
    margin-top: -80px;
    margin-bottom: -270px;
    display: block;
  }
}

* {
  box-sizing: border-box;
  font-feature-settings: "pnum" on, "lnum" on;
  margin: 0;
  font-family: Raleway, sans-serif;
  font-weight: 400;
}

html, body {
  overflow-x: hidden;
}

/*# sourceMappingURL=index.7df1ab28.css.map */
