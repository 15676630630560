.section-5 {
    padding: 130px 0 188px;
}

.s5__small {
    display: block;
    text-align: center;
    font-weight: 500;
    font-size: 20px;
    color: #75849E;
}
.s5__headline {
    font-weight: 600;
    font-size: 40px;
    color: #1265FA;
    max-width: 740px;
    text-align: center;
    margin: 10px auto;
}

.s5__controls {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
}
.s5__control-left {
    margin-left: 900px;
}
.slides__control_secondary {
    background: #EAEDF8;
}

.glide__track.s5__track {
    width: 560px;
    margin: 0 auto;
    overflow: visible;
}
.s5__slide {
    flex-basis: 260px;
    height: 360px;
    background: #EAEDF8;
    border-radius: 40px;
}

.s5__controls-mobile {
    display: none;
}

.s5__bottom-wrapper {
    width: 560px;
    margin: 56px auto 0;
    overflow: visible;
}
.s5__bottom {
    margin-left: 300px;
    font-weight: 500;
    font-size: 20px;
    color: #75849E;
    width: 100%;
}
.s5__socials {
    padding-top: 7px;
    vertical-align: middle;
}
.s5__social {
    background: #EAEDF8;
    border-radius: 99px;
    padding: 0 30px 0 20px;
    text-decoration: none;
    color: #75849E;
    display: inline-block;
    font-size: 20px;
    line-height: 40px;
    height: 40px;
}
.s5__social-image {
    line-height: 40px;
    vertical-align: middle;
    margin-right: 12px;
}

@media screen and (max-width: 1200px) {
    .s5__control-left {
        margin-left: 300px;
    }
}
@media screen and (max-width: 870px) {
    .s5__controls {
        display: none;
    }
    .s5__controls-mobile {
        display: block;
        text-align: right;
        margin-right: 39px;
        margin-top: 6px;
    }
    .s5__small {
        font-size: 15px;
    }
    .s5__headline {
        font-size: 25px;
        margin-bottom: 40px;
        max-width: 310px;
    }
    .section-5 {
        padding: 72px 0 62px;
    }
}
@media screen and (max-width: 800px) {
    .s5__bottom {
        margin: 33px 0 0;
        font-size: 15px;
    }
    .s5__social {
        font-size: 17px;
        height: 33px;
        line-height: 33px;
    }
    .s5__social-image {
        width: 18px;
        height: auto;
        margin-right: 10px;
    }
    .s5__bottom-wrapper {
        width: initial;
        margin: 0 auto;
    }
    .s5__bottom {
        text-align: center;
    }
}
@media screen and (max-width: 750px) {
    .glide__track.s5__track {
        width: 230px;
        margin: 0 0 0 40px;
    }
    .s5__slide {
        flex-basis: 230px;
    }
}
