.section-2 {
    padding-top: 120px;
    padding-bottom: 170px;
}

.section-2__inner {
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    max-width: 1160px;
    list-style: none;
    padding: 0 20px;
}

.section-2__card {
    flex-basis: 330px;
    max-width: 330px;
}

.s2-image__wrap {
    height: 460px;
    display: flex;
    align-items: center;
}

.s2-card__title {
    padding-top: 18px;
    font-weight: 600;
    font-size: 28px;
    color: #1265FA;
    padding-bottom: 20px;
    border-bottom: 2px solid #BDD5FF;
    margin-bottom: 30px;
}
.s2-card__subtitle {
    font-weight: 500;
    font-size: 20px;
    color: #75849E;
}

@media screen and (max-width: 1075px) {
    .section-2__inner {
        max-width: 800px;
    }
    .section-2__card.mobile-hide {
        display: none;
    }
}
@media screen and (max-width: 725px) {
    .section-2__card.mobile-hide {
        display: block;
    }
    .section-2__inner {
        max-width: 370px;
        flex-direction: column;
    }
    .section-2__card {
        margin-bottom: 85px;
    }
}
@media screen and (max-width: 400px) {
    .s2-image__wrap, .s2-image {
        width: 100%;
        height: auto;
    }
}
