.section-8 {
    padding: 140px 20px 155px;
    background: #F9FAFF;
}

.s8__inner {
    max-width: 1160px;
    margin: 0 auto;
    position: relative;
}

.s8__video {
    position: relative;
    display: block;
    height: 600px;
}
.s8__image-back, .s8__image-front {
    position: absolute;
    left: 0;
    bottom: 0;
}

.s8__card-wrap {
    position: absolute;
    right: 0;
    bottom: 0;
    max-width: 500px;
}
.s8__card {
    padding: 28px 40px 40px;
    border-radius: 40px;
    background: white;
}

.s8__subtitle {
    font-weight: 500;
    font-size: 20px;
    line-height: 140%;
    color: #1265FA;
    margin-bottom: 1px;
}
.s8__title {
    font-weight: 600;
    font-size: 40px;
    line-height: 130%;
    color: #1265FA;
    margin-bottom: 24px;
}

.emoji-list_s8 {
    padding-bottom: 50px;
}

.emoji-list__item_s8 {
    padding-bottom: 7px;
    align-items: flex-start;
}
.emoji-list__emoji_s8 {
    padding-right: 26px;
}

.s8__button {
    width: 100%;
}

.s8__note {
    text-align: center;
    margin-top: 34px;
    font-weight: 500;
    font-size: 20px;
    line-height: 140%;
    color: #75849E;
}
.s8__note b {
    font-weight: 700;
}

.s8__button-mobile-wrap {
    display: none;
}
.s8__subtitle_top {
    display: none;
}

@media screen and (max-width: 1000px) {
    .s8__title {
        font-size: 25px;
    }
    .emoji-list__item_s8 {
        font-size: 15px;
        padding-bottom: 5px;
    }
    .emoji-list__emoji_s8 {
        font-size: 24px;
    }
}
@media screen and (max-width: 900px) {
    .s8__card-wrap {
        max-width: 420px;
    }
}
@media screen and (max-width: 800px) {
    .section-8 {
        padding-top: 70px;
        padding-bottom: 102px;
    }
    .s8__card-wrap {
        padding: 0 20px;
        max-width: 560px;
        position: static;
        margin: 10px auto;
    }
    .s8__card {
        padding: 19px 23px 34px;
    }
    .s8__subtitle {
        display: none;
    }
    .s8__subtitle_top {
        display: block;
        padding-left: 20px;
        margin-bottom: -30px;
        font-size: 15px;
    }
    .s8__video {
        display: block;
        overflow-x: visible;
        width: 100%;
        max-width: 560px;
        margin: 0 auto;
        padding: 0 20px;
        position: relative;
        height: 600px;
    }
    .s8__video-inner {
        position: relative;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
    .s8__image-front {
        width: 100%;
    }
    .s8__image-back {
        width: 139%;
    }
    .emoji-list_s8 {
        padding-bottom: 0;
    }
    .s8__button {
        display: none;
    }
    .s8__button-mobile-wrap {
        display: block;
        margin-top: 22px;
        padding: 0 20px;
        text-align: center;
    }
    .s8__button-mobile {
        width: 100%;
        max-width: 520px;
    }
    .s8__note {
        margin-top: 22px;
        font-size: 15px;
    }
}
@media screen and (max-width: 520px) {
    .s8__video {
        height: 410px;
    }
}
@media screen and (max-width: 420px) {
    .s8__video {
        height: 330px;
    }
}
