.section-6 {
    padding: 145px 20px;
    margin: 0 auto;
    max-width: 1200px;
}

.s6__headline {
    font-weight: 600;
    font-size: 40px;
    line-height: 130%;
    color: #1265FA;
    padding-bottom: 25px;
    max-width: 700px;
}
.s6__headline u {
    font-weight: 600;
}

.s6__table {
    width: 100%;
    border-collapse: collapse;
}

.s6__thead > tr > td {
    border-bottom: 2px solid #1265FA;
    padding: 20px 0;
}
.s6__thead.s6__mobile-hide > tr > td {
    padding-top: 74px;
}
.s6__accent {
    font-weight: 600;
    font-size: 28px;
    line-height: 130%;
    color: #1265FA;
}
.s6__secondary {
    font-weight: 500;
    font-size: 20px;
    line-height: 140%;
    color: #75849E;
}
.s6__secondary > u {
    font-weight: 500;
}

.s6__left {
    min-width: 200px;
}

.s6__row > td:not(.s6__left) {
    border-bottom: 2px solid #BDD5FF;
    padding-top: 10px;
    padding-bottom: 40px;
    padding-right: 10px;
    vertical-align: top;
}
.s6__row:last-child > td {
    border-bottom: none;
}

.s6__row > td.s6__image {
    padding-top: 15px;
    padding-right: 40px;
    width: 60px;
}

.s6__type {
    font-weight: 600;
    font-size: 20px;
    line-height: 140%;
    color: #191919;
}

.s6__price-mobile {
    display: none;
}

.s6__mobile-num {
    display: none;
}

@media screen and (max-width: 1000px) {
    .s6__left {
        min-width: 100px;
    }
}

@media screen and (max-width: 840px) {
    .section-6 {
        padding: 87px 40px 50px;
    }
    .s6__headline {
        text-align: center;
        width: 100%;
        padding-bottom: 30px;
        font-size: 25px;
    }
    .s6__row > td.s6__image {
        border-bottom: none;
    }
    .s6__secondary {
        display: none;
    }
    .s6__price-mobile {
        display: flex;
        padding-top: 6px;
        justify-content: space-between;
        font-size: 15px;
    }
    .s6__price-right {
        text-align: right;
    }
    .s6__left {
        display: none;
    }
    .s6__thead > tr > td {
        border-bottom: none;
        padding: 0;
    }
    .s6__thead > tr > td.s6__subtitle {
        padding-bottom: 13px;
        border-bottom: 2px solid #1265FA;
    }
    .s6__accent {
        font-size: 25px;
    }
    .s6__mobile-num {
        display: block;
        font-weight: 600;
    }
    .s6__row > td.s6__image {
        padding-right: 20px;
    }
    .s6__type {
        font-size: 15px;
    }
    .s6__mobile-hide {
        display: none;
    }
}
