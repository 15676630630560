.section-10 {
    background: #EAE9E7 url(../assets/s10-p1.png) no-repeat top 29px right 52px;
    background-size: contain;
}

.s10__inner {
    max-width: 1200px;
    margin: 0 auto;
    padding: 100px 20px;
}

.s10__small {
    font-weight: 500;
    font-size: 20px;
    line-height: 140%;
    color: #75849E;
}

.s10__headline {
    font-weight: 600;
    font-size: 40px;
    line-height: 130%;
    color: #1265FA;
    margin: 10px 0 43px;
    max-width: 450px;
}

.s10__video {
    background: #EAE9E7;
    border: 4px solid #5693FF;
    border-radius: 40px;
    display: block;
    width: 588px;
    max-width: 100%;
    height: auto;
    position: relative;
    margin-bottom: 42px;
}
.s10__video-preview {
    border-radius: 35px;
    max-width: 100%;
}
.s10__play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.s10__cards {
    display: flex;
    justify-content: flex-start;
}
.s10__card {
    width: 250px;
    height: 204px;
    padding: 20px;
    background: white;
    border-radius: 20px;
    margin-right: 11px;
}
.s10__card-emoji {
    background: #EAEDF8;
    border-radius: 99px;
    width: 60px;
    height: 60px;
    line-height: 60px;
    vertical-align: middle;
    text-align: center;
    margin-bottom: 20px;
    font-size: 36px;
}
.s10__card-text {
    font-weight: 500;
    font-size: 20px;
    line-height: 140%;
    color: #75849E;
}

.s10__button {
    text-align: center;
    line-height: 204px;
    padding: 0;
    margin-left: 28px;
}

.s10__mobile-image {
    display: none;
}

@media screen and (max-width: 1150px) {
    .s10__cards {
        flex-wrap: wrap;
    }
    .s10__button {
        height: 70px;
        line-height: 70px;
        margin-left: 0;
        margin-top: 20px;
        max-width: 313px;
        width: 100%;
    }
    .section-10 {
        background-size: 60%;
        background-position: top 20px right;
    }
}

@media screen and (max-width: 850px) {
    .section-10 {
        background: #EAE9E7 none;
    }
    .s10__cards {
        flex-direction: column;
    }
    .s10__card {
        height: 93px;
        max-width: 400px;
        width: 100%;
        margin-right: 0;
        margin-bottom: 5px;
        display: flex;
        flex-direction: row;
        padding: 16px;
        align-items: center;
    }
    .s10__card-emoji {
        flex-shrink: 0;
        margin-right: 23px;
        margin-bottom: 0;
    }
    .s10__card-text {
        font-size: 15px;
    }
    .s10__button {
        margin-top: 37px;
    }
    .s10__small {
        font-size: 15px;
    }
    .s10__headline {
        font-size: 25px;
    }
    .s10__mobile-image {
        display: block;
        margin-bottom: -270px;
        margin-top: -80px;
    }
}
