.section__top {
    width: 100%;
    min-height: 100vh;
    background: url(../assets/top.jpg) no-repeat top center;
    background-size: cover;
    position: relative;
    padding-bottom: 70px;
}

.top-card {
    max-width: 497px;
    margin: 64px auto 0;
    border-radius: 21px;
    padding: 32px 0;
    background-color: white;
    z-index: 10;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.top-card__headline {
    color: #0085FF;
    text-transform: uppercase;
    text-shadow: 0 1px 0 #0258A8;
    font-weight: 700;
    font-size: 38px;
    text-align: center;
    padding-bottom: 30px;
}

.emoji-list {
    display: inline-block;
    list-style: none;
    padding-inline-start: 0;
    padding-bottom: 40px;
}
.emoji-list__item {
    color: #1265FA;
    font-size: 20px;
    padding-bottom: 10px;

    /* workaround FOR A STUPID FIREFOX BUG. see https://imgur.com/ev0FYZP (chrome|firefox|webkit) */
    max-width: 400px;
    display: flex;
    align-items: center;
}
.emoji-list__emoji {
    font-size: 28px;
    vertical-align: middle;
    padding-right: 16px;
}

.top-card__promo {
    font-weight: 500;
    font-size: 20px;
    text-align: center;
    color: #1265FA;
    max-width: 400px;
    /* there's also a gradient, but it's barely noticeable */
    padding-bottom: 20px;
}
.top-card__promo b {
    font-weight: 700;
}

.giant-input {
    background: #EAEDF8;
    border-radius: 999px;
    border: none;
    padding: 20px 40px;
    font-weight: 500;
    font-size: 20px;
    line-height: 140%;
    color: #1265FA;
    margin-bottom: 20px;
    width: 335px;
    max-width: 100%;
}
.giant-button {
    background: #EC6900;
    background: linear-gradient(180deg, #F8A100 0%, #EC6900 100%);
    border-radius: 999px;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: white;
    border: none;
    padding: 20px 40px;
    cursor: pointer;
    width: 335px;
    max-width: 100%;
    text-decoration: none;
}

.top-card__small {
    max-width: 335px;
    padding: 20px 0 2px;
    /* guess what? there should be a gradient as well */
    color: #F8A100;
    font-weight: 400;
    font-size: 10px;
    text-align: center;
}

@media screen and (max-width: 1020px) {
    .section__top {
        background-color: #EBEBEB;
        background-image: url(../assets/top-mobile.png);
        background-repeat: no-repeat;
        background-size: contain;
        background-position-y: 34px;
    }
    .section__top::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: linear-gradient(180.2deg, rgba(235, 235, 235, 0) 28.56%, #EBEBEB 39.89%);
    }
    .top-card__wrap {
        padding: 0 16px;
    }
    .top-card {
        margin: 160px auto 90px;
        padding-left: 20px;
        padding-right: 20px;
    }
    .top-card__headline {
        font-size: 27px;
        padding-bottom: 10px;
    }
    .emoji-list__item {
        font-size: 16px;
    }
    .emoji-list__item::first-letter {
        font-size: 24px;
    }
    .emoji-list {
        padding-bottom: 17px;
        width: 100%;
    }
    .emoji-list__item {
        padding-bottom: 4px;
    }
}
