.section-7 {
    background: url(../assets/s7-p2.png) no-repeat top center;
    background-size: 75%;
    min-height: 904px;
    position: relative;
}

.s7__hand {
    position: absolute;
    top: 0;
    right: 50%;
    transform: translateX(50%);
    width: 75%;
    height: 100%;
    background: url(../assets/s7-p1.png) no-repeat top center;
    background-size: 100%;
    border-top-right-radius: 29px;
}

.s7__card {
    position: absolute;
    top: 20%;
    right: 52%;
    padding: 30px 35px 80px 50px;
    background: white;
    border-radius: 45px;
    width: 644px;
}

.s7__headline {
    font-weight: 600;
    font-size: 40px;
    line-height: 130%;
    color: #0085FF;
    max-width: 470px;
    padding-bottom: 5px;
}
.s7__text {
    font-weight: 500;
    font-size: 20px;
    line-height: 140%;
    color: #75849E;
    max-width: 360px;
}

.s7__bottom {
    position: absolute;
    bottom: 130px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
}
.s7__bottom-text {
    text-align: center;
    font-weight: 500;
    font-size: 20px;
    line-height: 140%;
    color: #75849E;
    margin: 20px 0;
}
.s7__socials {
    display: flex;
}
.s7__social-col {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.s7__social {
    width: 90px;
    height: 90px;
    margin: 20px 20px 0;
    background: #1265FA;
    border-radius: 999px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.s7__social > img {
    width: 50px;
    height: auto;
    filter: brightness(10);
}

@media screen and (max-height: 840px) {
    .section-7 {
        min-height: 780px;
    }
    .s7__bottom {
        bottom: 15px;
    }
}

@media screen and (max-width: 1400px) {
    .s7__card {
        width: 500px;
        right: 55%;
    }
    .section-7 {
        background-size: 100%;
    }
    .s7__hand {
        background-size: 100%;
        width: 100%;
    }
}

@media screen and (max-width: 1140px) {
    .s7__hand {
        background-size: 75%;
    }
    .section-7 {
        background-size: 75%;
        min-height: 600px;
    }
    .s7__card {
        top: -10%;
        right: 50%;
    }
}

@media screen and (max-width: 950px) {
    .s7__card-wrapper {
        max-width: 309px;
        padding: 217px 0 0;
        margin: 0 auto;
    }
    .s7__card {
        max-width: 309px;
        position: static;
        padding: 380px 23px 40px;
        border-radius: 10px;
    }
    .s7__headline {
        font-size: 25px;
        padding-bottom: 15px;
    }
    .s7__text {
        font-size: 15px;
    }
    .section-7 {
        background-size: cover;
        background-position: top left;
    }
    .s7__hand {
        background-size: 172%;
        background-position: top 0 right -30px;
    }
    .s7__bottom {
        position: static;
        flex-direction: column-reverse;
        margin: 0 auto;
        transform: none;
    }
    .s7__bottom-text {
        font-size: 15px;
    }
    .s7__socials {
        transform: scale(85%);
    }
}

@media screen and (max-width: 700px) {
    .s7__card {
        padding: 220px 23px 40px;
    }
}

@media screen and (max-width: 480px) {
    .s7__card {
        padding: 75px 23px 200px;
    }
    .s7__bottom {
        margin-top: -190px;
        padding-bottom: 120px;
    }
}
