.section-3 {
    background: #EAEDF8;
    padding: 140px 0;
}
.section-3__inner {
    background: #1265FA;
    border-radius: 90px;
    display: flex;
    max-width: 1160px;
    margin: 0 auto;
    color: white;
}

.s3__img {
    max-width: 100%;
}
.s3__img_top {
    border-radius: 90px 90px 0 0;
}
.s3__img_bottom {
    margin-top: -250px;
    margin-bottom: -4px;
    border-radius: 0 0 90px 90px;
}

.s3-right {
    padding: 70px 40px 70px 80px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.s3__h2 {
    font-weight: 600;
    font-size: 40px;
    padding-bottom: 28px;
}

.s3__list {
    list-style: none;
    padding: 0;
}
.s3__list-item {
    font-weight: 500;
    font-size: 20px;
    display: flex;
    padding-bottom: 25px;
    line-height: 140%;
}
.s3__list-emoji {
    flex-basis: 60px;
    flex-shrink: 0;
    width: 60px;
    height: 60px;
    background: white;
    border-radius: 99px;
    margin-right: 25px;
    line-height: 60px;
    text-align: center;
    font-size: 30px;
}

.s3__img_right {
    display: none;
}

.s3__h3 {
    font-weight: 600;
    font-size: 36px;
    padding-bottom: 12px;
}
.s3__text {
    font-weight: 500;
    font-size: 20px;
    line-height: 140%;
    padding-bottom: 12px;
}
.s3__text b {
    font-weight: 700;
}

.s3__form {
    padding-top: 44px;
}

.giant-input.giant-input_s3 {
    padding: 20px 44px;
    width: 400px;
    max-width: 100%;
    background: transparent;
    color: white;
    border: 2px solid #D3DDF8;
}
.giant-button.giant-button_s3 {
    padding: 16px 0;
    width: 400px;
    max-width: 100%;
    font-size: 20px;
}
.giant-button_s3::first-letter {
    font-size: 30px;
    vertical-align: middle;
}

.s3__small {
    display: block;
    font-size: 10px;
    line-height: 120%;
    margin-left: 22px;
    margin-top: 20px;
    margin-right: 60px;
}

@media screen and (max-width: 1100px) {
    .s3-left {
        flex-basis: 40%;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .s3__img_bottom {
        margin-top: 0;
    }
}

@media screen and (max-width: 900px) {
    .s3-left {
        flex-basis: 40%;
    }
    .s3-right {
        padding-left: 30px;
        padding-right: 20px;
    }
}

@media screen and (max-width: 750px) {
    .section-3 {
        padding: 0;
        background: none;
    }
    .section-3__inner {
        flex-direction: column;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
    .s3__img_bottom {
        display: none;
    }
    .s3-right {
        padding: 0;
    }
    .s3__h2 {
        font-weight: 600;
        font-size: 25px;
        padding: 0 24px 22px;
    }
    .s3__list-item {
        font-weight: 500;
        font-size: 15px;
        line-height: 140%;
        padding: 0 24px 15px;
    }
    .s3__list-item_pad-right {
        max-width: 80%;
    }
    .s3__img_right {
        display: block;
        margin-top: -135px;
        width: 100%;
    }
    .s3-bottom {
        padding: 0 0 100px;
    }
    .s3__h3 {
        padding: 70px 24px 15px;
        font-weight: 600;
        font-size: 25px;
        text-align: center;
    }
    .s3__text {
        padding: 0 24px 2px;
        font-size: 17px;
        text-align: center;
    }
    .s3__form {
        max-width: 326px;
        margin: 0 auto;
    }
    .s3__small {
        text-align: center;
        margin: 10px 64px 0 64px;
    }
}
