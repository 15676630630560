@import url(../../node_modules/@glidejs/glide/dist/css/glide.core.min.css);
@import url(./header.css);
@import url(./top.css);
@import url(./section1.css);
@import url(./section2.css);
@import url(./section3.css);
@import url(./section4.css);
@import url(./section5.css);
@import url(./section6.css);
@import url(./section7.css);
@import url(./section8.css);
@import url(./section9.css);
@import url(./section10.css);

* {
    margin: 0;
    box-sizing: border-box;
    font-family: "Raleway", sans-serif;
    font-weight: 400;
    font-feature-settings: 'pnum' on, 'lnum' on;
}

html, body {
    overflow-x: hidden;
}
