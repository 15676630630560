.header {
    max-width: 1160px;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    padding: 7px 10px 0;
    position: relative;
}

.header__logo {
    padding-right: 16px;
}

.header__description {
    padding-top: 5px;
    line-height: 1.4;
    color: #5B6270;
}
.header__description b {
    font-weight: 700;
}
.header__location-icon {
    height: 14px;
}

.header__nav {
    padding-top: 8px;
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    margin-left: 56px;
    margin-right: 56px;
}
.nav-item {
    color: #5B6270;
    text-decoration: none;
}
.nav-item:hover {
    text-decoration: underline;
}

.header__call {
    padding-top: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 14px;
}
.header__number {
    text-decoration: none;
    color: #5B6270;
    font-weight: bold;
    font-size: 20px;
}
.header__call-me-back {
    font-size: 14px;
    color: #5B6270;
}

/* i wanted to name it .header__social, but it's being hidden by uBlock */
.header__buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* the whole container is quite high, but we don't want .header to be that high */
    height: 0;
    overflow-y: visible;
}
.circle-button {
    background: #00ED89;
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;

    margin-bottom: 6px;
    width: 28px;
    height: 28px;
    flex-shrink: 0;
}
.circle-button_big {
    margin-bottom: 13px;
    width: 44px;
    height: 44px;
}
.circle-button_gold {
    background: #F59201;
}

.header__menu-toggle {
    display: none;
}

@media screen and (max-width: 1100px) {
    .header__nav {
        margin-left: 28px;
        margin-right: 28px;
    }
}
@media screen and (max-width: 1020px) {
    .header__nav {
        display: none;
    }
    .header__call {
        display: none;
    }
    .header__buttons {
        display: none;
    }
    .header {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    .header__logo {
        width: 150px;
        height: auto;
        padding-right: 0;
    }
    .header__description {
        margin-top: -15px;
    }
    .header__menu-toggle {
        display: block;
        position: absolute;
        top: 26px;
        right: 40px;
    }
}
